/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

export { wrapPageElement } from "./gatsby-shared"

export const onClientEntry = () => {

  if(window.location.hostname === "www.exclusivelycornwall.co.uk" && window.location.pathname !== '/maintenance'){
    window.location = '/maintenance';
  }
}

export const onRouteUpdate = () => {
  if(window.location.hostname === "www.exclusivelycornwall.co.uk" && window.location.pathname !== '/maintenance'){
    window.location = '/maintenance';
  }
}