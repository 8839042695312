import React from "react"
import { Script } from "gatsby"
import { ApolloProvider } from '@apollo/react-hooks';
import client from './src/apollo/client';

export const wrapPageElement = ({ element }) => {
  return (

     <ApolloProvider client={client}>
      {element}
      <Script id="zsiqchat" strategy="idle">
        {`
        var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "dc0b9e6f4d8e097bc9e2d2ffec87f5bdc960e9621f7ccda27bd03484c7c1393d5c69f6211fcab4ef3073b1b199ffa0d1", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);
        `}
      </Script>
     </ApolloProvider>

  )
}
